
export const environment = {
    production: false,
    apiHost: 'https://vendure-dev-api.innity.com.my',
    apiPort: null,
    shopApiPath: 'shop-api',
    baseHref: '/',
    tokenMethod: 'bearer',
    novuApplicationIdentifier: 's8ONsCGfYoUY',
    firebaseConfig: {
        apiKey: 'AIzaSyBBBxnuQCVVXV_vR5YhPrwCS-n-iJ9ktk8',
        authDomain: 'vendure-app-demo.firebaseapp.com',
        projectId: 'vendure-app-demo',
        storageBucket: 'vendure-app-demo.firebasestorage.app',
        messagingSenderId: '1012751272104',
        appId: '1:1012751272104:web:99208b08353d836e83da9d',
        measurementId: 'G-ZE6GH5CP63'
    },
    vapid: 'BOYrCE0eSsMi2FXzA_A7za4qGGK6CX_RmNauyPocdyyoiWZlhls6B8YRnl0W1TWiJrN4xobuOXXyycTvWCwJJno',
    pushProviderIdentifier: 'firebase-vendure-storefront'
};
